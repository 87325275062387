import * as React from 'react';

import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
export default function BasicCard(props) {
  const { topic, extraText, imgPath, imgTitle } = props;

  return <>
        <CardMedia
            sx={{ height: "300px" }}
            image={imgPath}
            title={imgTitle}
            component="img"
            draggable={false}
        />
        <CardContent>
          <Typography          
          sx={{fontSize: {
                  lg: 32,
                  md: 28,
                  sm: 26,
                  xs: 24
            }}}
            component="div" color="#0b5099">
            {topic}
          </Typography>
          <Typography 
          sx={{fontSize: {
                  lg: 15,
                  md: 15,
                  sm: 13,
                  xs: 12
          }}}
          color="#0b5099">
            {extraText}
          </Typography>
        </CardContent></>;
}